/* clearfix */
.landing {
  &:global(.page) {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}
