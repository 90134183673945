

button.gifts-button {
  margin: 0;
  &[data-component^="button"] {
    margin: 0;
  }

  svg {
    vertical-align: middle;
  }
}